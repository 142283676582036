import React from "react";
import "./login.css";
import { useState } from 'react';
import { loginFields } from "../../Constants/formFields";
import Input from "../../Components/Input/Input";


const fields=loginFields;
let fieldsState = {};
fields.forEach(field=>fieldsState[field.id]='');
const LoginPage = () => {
  const [loginState,setLoginState]=useState(fieldsState);

  const handleChange=(e)=>{
      setLoginState({...loginState,[e.target.id]:e.target.value})
  }
  return(
    <div className="flex h-screen">
    <div className="w-1/2 bg-cover bg-center relative" style={{ backgroundImage:`url("/login.png")`}}>
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center p-10 text-white">
      <img
          src="/Caprock-Logo.png"
          alt="Logo"
          className="absolute top-8 left-8"
        />
        <div className="absolute text-prop bottom-0 left-0 mb-8 ml-8 text-white text-xl">
        <h1 className="mb-4 drop-shadow-md side-text">Start your investment journey with <b>Caprock</b></h1>
        <p className="side-text-next">
        Join Caprock today and start investing with <b>0 commission*</b> </p>
        <a href="#" className="text-blue-500 learn-more hover:underline">
                Learn more
              </a>
        </div>
      </div>
    </div>
    <div className="w-1/2 flex flex-col justify-center items-center p-10">
      <h2 className="text-3xl header mb-6">Log in to Caprock</h2>
      <form className="w-full max-w-sm">
        <div className="mb-4">
        {
                fields.map(field=>
                        <Input
                            key={field.id}
                            handleChange={handleChange}
                            value={loginState[field.id]}
                            labelText={field.labelText}
                            labelFor={field.labelFor}
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            isRequired={field.isRequired}
                            placeholder={field.placeholder}
                    />
                
                )
            }
      <div className="flex items-center pt-4 justify-between mb-6">
              <div className="flex items-center">
              <input
                  type="checkbox"
                  id="remember"
                  className="h-4 w-4 text-blue-500 border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                />
                <label htmlFor="remember" className="ml-2 after-login">
                  Remember Me
                </label>
              </div>
              <a href="#" className="text-blue-500 after-login hover:underline">
                Forgot Password?
              </a>
            </div>
        </div>
        <button
              type="submit"
              className="w-full login-button text-white font-bold py-3 rounded flex items-center justify-center gap-2 hover:bg-blue-600 transition duration-300"
            >
              <img
                src="/profile-circle.png"
                alt="Login Icon"
                className="h-6 w-6"
              />
              Login
            </button>
      </form>
      <div className="mt-6 w-full max-w-sm text-center">
            <p className="text-gray-600">
              Don’t have an account?{" "}
              <button
              type="submit"
              className="w-full mt-2 signup-button text-black font-bold py-3 rounded flex items-center justify-center gap-2 hover:bg-blue-600 transition duration-300"
            >
              <img
                src="/user-circle-add.png"
                alt="Login Icon"
                className="h-6 w-6"
              />
              Sign up
            </button>
            </p>
          </div>
    </div>
  </div>

  )
}
export default LoginPage;
