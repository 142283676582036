import logo from './logo.svg';
import './App.css';
import "@fontsource/work-sans"; 

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import LoginPage from './Pages/Login/login';

function App() {
  return (

     <BrowserRouter>
        <Routes>
            <Route path="/" element={<LoginPage/>} />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
